import React from 'react';
import { Link } from "react-router-dom";

const Bottomd = ({ something }) => { 
    return (
        <div className="Bottom">
            <p>All Rights Reserved 🐕 2023-2025</p>
            <Link className="terms-nav-button" to="/terms">Terms</Link>
        </div>
    );
};

export default Bottomd;