import React from 'react'
import Topper from '../topper';
import Bottomd from '../bottomd';

  function Terms() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="branding-logo">
            <Topper />
          </div>
        </header>
        <div className="Welcome-new-main">
        <p>terms of service and usage</p>
      </div>

      <div className="App-body">
        <div class="collection-defs">
          <h2>definitions</h2>
            <p>Digital Snow ("we, us, company, website")<br />
            Cloudflare ("waf, other security system")<br />
            Herotofu contact form ("form")<br />
            Visitor or submitter ("user, you")</p>
        </div>

          <div class="collection-item">The website is to be used for marketing purposes, to explain our offerings, products, and share information about our company and employees. Additionally, the website can be used to contact us for inquiries or concerns. We own all intellectual property contained therein. </div>
            <br />

          <div class="collection-item">Users are prohibited from activities that would harm the website or company, including but not limited to denial of service, pentesting, throttling or defacement. </div>
            <br />

          <div class="collection-item">Users are obligated to report any suspected malicious activities. </div>
            <br />

            <div class="collection-item">The governing law of the company is the United States of America, state of Colorado, city of Denver jurisdictions. </div>
            <br />

          <div class="collection-item">Disclaimer of warranties: this website and its contents are provided “as is” only and our company liability is limited. We reserve all rights and the right to modify our content or company offerings at any time. This website and its contents may not be reproduced without written permission. </div>
            <br />

          <div class="collection-item">For any questions about these policies or those policies found within our Privacy Policy, or to exercise your rights, please use the contact us form on our main page.  </div>
            <br />

          <div class="collection-item">Continued use of our website and form constitutes acceptance of these terms and conditions.  </div>
            <br />

          <div class="Welcome-new-main">last update: dec 26, 2024</div>

      </div>  
      <div>
            <Bottomd />
          </div>
      </div>
    )
  }

export default Terms;
