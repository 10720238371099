import React, { useState } from "react";
import styled from 'styled-components';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/3caf8720-9be0-11ee-9dec-893a2542e245";

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--bg-primary);
  padding: 40px 20px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 480px;
  background-color: var(--bg-primary);
  position: relative;
  z-index: 1;
`;

const HeroForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--bg-primary);
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  gap: 24px;
`;

const inputStyles = `
  width: 100%;
  padding: 12px 16px;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border-color: #007BFF;
  }
`;

const Input = styled.input`
  ${inputStyles}
`;

const Textarea = styled.textarea`
  ${inputStyles}
  resize: none;
  height: 120px;
`;

const Button = styled.button`
  width: 100%;
  padding: 14px 24px;
  margin: 0;
  border: none;
  border-radius: 5px;
  background-color: var(--bg-secondary);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Saira', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: var(--bg-secondary);

  &:hover {
    background-color: var(--bg-secondary);
    transform: translateY(-1px);
    background-color: var(--bg-secondary);
  }

  &:active {
    transform: translateY(0);
    background-color: var(--bg-secondary);
  }
`;

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    
    try {
      const response = await fetch(FORM_ENDPOINT, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json'
        }
      });
      
      if (response.ok) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  if (submitted) {
    return (
      <HeroContainer>
        <div className="Welcome-new-main">
          <p>Thank you. We'll be in contact soon!</p>
        </div>
      </HeroContainer>
    );
  }

  return (
    <HeroContainer>
      <StyledForm
        action={FORM_ENDPOINT}
        method="POST"
        onSubmit={handleSubmit}
      >
        <HeroForm>
          <Input type="text" placeholder="Name" name="name" required />
          <Input type="email" placeholder="Email" name="email" required />
          <Textarea placeholder="Share some details" name="message" required></Textarea>
          <Button type="submit">Send</Button>
        </HeroForm>
      </StyledForm>
    </HeroContainer>
  );
};

export default ContactForm;
